<app-alert></app-alert>
<app-placeholder-table-widgets *ngIf="!widgets"></app-placeholder-table-widgets>

<div *ngIf="widgets" class="wrapper">
    <header class="header">
        <app-breadcrumbs [crumbs]="getCrumbs(currentConnection.title || currentConnection.database)" class="row-breadcrumbs"></app-breadcrumbs>

        <div *ngIf="widgets.length" class="header-actions">
            <button  mat-stroked-button
                type="button"
                (click)="openClearAllConfirmation()"
                [disabled]="fields.length === 0 && widgets.length === 0">
                Clear All
            </button>
            <button mat-stroked-button color="accent"
                type="button"
                angulartics2On="click"
                angularticsAction="Widgets: add widget is clicked"
                (click)="addNewWidget()"
                [disabled]="fields.length === 0 || widgets.length === fieldsCount">
                <mat-icon>add</mat-icon>
                Add widget
            </button>
        </div>

    </header>

    <form *ngIf="widgets && widgets.length; else emptyState" (ngSubmit)="updateWidgets()" class="widget-settings">
        <app-widget *ngFor="let widget of widgets; let i = index"
            class="widget-item"
            [index]="i"
            [widgetType]="widget.widget_type"
            [widget]="widget"
            [fields]="fields"
            [widgetTypes]="widgetTypes"
            [isReadonlyParams]="isReadOnly(widget.widget_type)"
            (onSelectWidgetField)="selectWidgetField($event)"
            (onWidgetTypeChange)="widgetTypeChange($event)"
            (onWidgetParamsChange)="widgetParamsChange($event)"
            (onWidgetDelete)="openDeleteWidgetDialog($event)">
        </app-widget>


        <div class="actions">
            <a mat-stroked-button routerLink="/dashboard/{{connectionID}}/{{tableName}}">
                Back
            </a>

            <button mat-flat-button color="primary"
                type="submit" [disabled]="submitting">
                Save
            </button>
        </div>
    </form>

    <ng-template #emptyState>
        <div class="empty-state">
            <p class="empty-state__text">No widgets added</p>
            <button mat-flat-button color="accent"
                angulartics2On="click"
                angularticsAction="Widgets: add first widget is clicked"
                (click)="addNewWidget()">
                <mat-icon>add</mat-icon>
                Add widget
            </button>

            <div class="actions">
                <button mat-stroked-button type="button" (click)="goBack()">
                    Back
                </button>

                <button mat-flat-button color="primary"
                    type="submit" disabled>
                    Save
                </button>
            </div>
        </div>
    </ng-template>
</div>