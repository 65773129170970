<h1 mat-dialog-title>Add member to <strong>{{company.name}}</strong> company</h1>
<form action="" #addUserForm="ngForm" (ngSubmit)="addCompanyMember()">
    <mat-dialog-content>
        <mat-form-field appearance="outline" class="add-member-input">
            <mat-label>Enter user email</mat-label>
            <input matInput type="email" [(ngModel)]="companyMemberEmail"
                name="email" #email="ngModel" required emailValidator [disabled]="submitting">
            <mat-error *ngIf="email.errors?.required && (email.invalid && email.touched)">Title should not be empty.</mat-error>
            <mat-error *ngIf="email.errors?.isInvalidEmail">Invalid email format.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="add-member-input">
            <mat-label>Role</mat-label>
            <mat-select name="memberType" #memberType="ngModel"
                [disabled]="submitting"
                [(ngModel)]="companyMemberRole">
                <mat-option value="ADMIN">Super admin</mat-option>
                <mat-option value="USER">Member</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="add-member-input">
            <mat-label>Group</mat-label>
            <mat-select name="connectionGroup" #connectionGroup="ngModel"
                [(ngModel)]="companyUsersGroup">
                <mat-option>-- None --</mat-option>
                <mat-optgroup *ngFor="let connection of groups"
                    [label]="connection.title">
                    <mat-option *ngFor="let group of connection.groups"
                        [value]="group.id">
                        {{group.title}}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" mat-flat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary"
            [disabled]="submitting || addUserForm.form.invalid">
            Add
        </button>
    </mat-dialog-actions>
</form>
