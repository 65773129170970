<app-alert></app-alert>

<div class="connectPage">
    <form action="" #connectForm="ngForm" class="form"
        (ngSubmit)="handleCredentialsSubmitting(connectForm)">
        <h1 class="mat-h1 connectForm__fullLine">
            {{ connectionID ? 'Edit credentials' : 'Connect a database' }}
        </h1>

        <mat-form-field appearance="outline" class="connectForm__title">
            <mat-label>Connection title</mat-label>
            <input matInput name="title" #title="ngModel"
                data-testid="connection-title-input"
                angulartics2On="change"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: title is edited"
                [required]="db.connectionType === 'agent'"
                [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                [disabled]="submitting"
                [(ngModel)]="db.title">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Database type</mat-label>
            <mat-select name="dbtype" #dbtype="ngModel"
                data-testid="connection-dbtype-select"
                angulartics2On="click"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: db type is selected"
                [angularticsProperties]="{'dbType': db.type}"
                [disabled]="submitting"
                [(ngModel)]="db.type"
                (ngModelChange)="dbTypeChange()" >
                <mat-option value="mysql">MySQL</mat-option>
                <mat-option value="postgres">PostgreSQL</mat-option>
                <mat-option value="oracledb">Oracle</mat-option>
                <mat-option value="mssql">MS SQL</mat-option>
                <mat-option value="mongodb">MongoDB</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="connectForm__fullLine">
            <mat-button-toggle-group name="connectionType"
                data-testid="connection-type-toggle"
                class="connectForm__typeSwitch"
                [disabled]="submitting || db.isTestConnection"
                [(ngModel)]="db.connectionType">
                <mat-button-toggle value="direct">Direct connection</mat-button-toggle>
                <mat-button-toggle value="agent">Agent connection</mat-button-toggle>
            </mat-button-toggle-group>
        </div>


        <!--<div class="connectForm__fullLine connectForm__agentRadio">
            <mat-radio-group required
                name="connectionType"
                class="radio-group"
                [disabled]="submitting"
                [(ngModel)]="db.connectionType">
                <mat-radio-button value='direct' class="radio-button">
                    Direct connection
                </mat-radio-button>
                <mat-radio-button value='agent' class="radio-button">
                    Agent connection
                </mat-radio-button>
            </mat-radio-group>
        </div>-->

        <div *ngIf="db && db.connectionType === 'direct' && !db.isTestConnection"
            data-testid="direct-connection-warning"
            class="connectForm__fullLine">
            <div *ngIf="connectionID; else warningAlert" class="warningMessage">
                <mat-icon class="warningMessage__icon">warning_amber</mat-icon>
                <div class="mat-body-1">
                    Please make sure our servers are able to access your database. <br/> Rocketadmin uses
                    <app-ip-address-button ip="18.221.81.73"></app-ip-address-button>
                    IP address for all outbound requests.
                </div>
            </div>

            <ng-template #warningAlert>
                <app-alert [alert]="warning">
                    <div class="mat-body-1" style="margin-bottom: 0">
                        Please make sure our servers are able to access your database. <br/> Rocketadmin uses
                        <app-ip-address-button ip="18.221.81.73"></app-ip-address-button>
                        IP address for all outbound requests.
                    </div>
                </app-alert>
            </ng-template>
        </div>

        <ng-container *ngIf="db.connectionType === 'direct'">
            <mat-form-field appearance="outline" class="connectForm__hostname">
                <mat-label>Hostname</mat-label>
                <input matInput name="hostname" #hostname="ngModel"
                    data-testid="connection-hostname-input"
                    angulartics2On="change"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: hostname is edited"
                    required hostnameValidator
                    [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                    [disabled]="submitting"
                    [(ngModel)]="db.host">
                <mat-hint>
                    E.g. <strong><code>my-test-db.cvfuxe8nltiq.us-east-2.rds.amazonaws.com</code></strong>.
                    Connections from internal IPs (e.g. localhost) are not supported.
                </mat-hint>

                <mat-error *ngIf="hostname.errors?.isLocalhost && hostname.invalid">
                    To connect a database on internal IP use <strong>ngrok</strong>
                    (<a href="https://docs.meroxa.com/guides/how-to-expose-postgresql-remotely-using-ngrok/" target="_blank"
                        class="connectForm__agent-connection-button">how-to</a>)
                    or <button (click)="switchToAgent()" class="connectForm__agent-connection-button">click here</button> for agent connection.
                </mat-error>
                <mat-error *ngIf="hostname.errors?.isInvalidHostname && hostname.invalid">Hostname is invalid.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="connectForm__port">
                <mat-label>Port</mat-label>
                <input matInput type="number" name="port" #port="ngModel"
                    data-testid="connection-port-input"
                    angulartics2On="change"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: port is edited"
                    required
                    [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                    [disabled]="submitting"
                    [(ngModel)]="db.port">
                <mat-error *ngIf="port.errors?.required && (port.invalid && port.touched)">Port should not be empty.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="connectForm__firstHalf">
                <mat-label>Username</mat-label>
                <input matInput name="username" #username="ngModel"
                    data-testid="connection-username-input"
                    angulartics2On="change"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: username is edited"
                    required
                    [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                    [disabled]="submitting"
                    [(ngModel)]="db.username">
                <mat-error *ngIf="username.errors?.required && (username.invalid && username.touched)">Username should not be empty.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="connectForm__secondHalf">
                <mat-label>Password</mat-label>
                <input type="password" matInput name="password" #password="ngModel"
                    data-testid="connection-password-input"
                    angulartics2On="change"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: password is edited"
                    [required]="!db.id || hostname.touched || port.touched"
                    [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                    [disabled]="submitting"
                    [(ngModel)]="db.password">
                <mat-hint *ngIf="db.id && (hostname.pristine && port.pristine)">To keep password the same keep this field blank.</mat-hint>
                <mat-hint *ngIf="db.id && (hostname.dirty || port.dirty)">Password needed due to hostname/port change.</mat-hint>
                <!-- <mat-error *ngIf="email.errors.required && (email.invalid && email.touched)">Email should not be empty.</mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline" [ngClass]="(db.type === 'mysql' || db.type === 'mongodb') ? 'connectForm__fullLine' : 'connectForm__firstHalf'">
                <mat-label>Database Name</mat-label>
                <input matInput name="database" #database="ngModel"
                    data-testid="connection-database-input"
                    angulartics2On="change"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: database name is edited"
                    required
                    [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                    [disabled]="submitting"
                    [(ngModel)]="db.database">
                <mat-error *ngIf="database.errors?.required && (database.invalid && database.touched)">Name should not be empty.</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="db.type !== 'mysql' && db.type !== 'mongodb'" appearance="outline" class="connectForm__secondHalf">
                <mat-label>Schema</mat-label>
                <input matInput name="schema" #schema="ngModel"
                    data-testid="connection-schema-input"
                    angulartics2On="change"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: database schema is edited"
                    [required]="db.type === 'mssql'"
                    [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                    [disabled]="submitting"
                    [(ngModel)]="db.schema">
                <mat-error *ngIf="schema.errors?.required && (schema.invalid && schema.touched)">Name should not be empty.</mat-error>
            </mat-form-field>

            <mat-expansion-panel class="connectForm__fullLine">
                <mat-expansion-panel-header data-testid="connection-advanced-settings-expansion-panel-header">
                    <mat-panel-title>
                        Advanced settings
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="advanced-settings">
                    <div class="encription-setting advanced-settings__fullLine">
                        <mat-slide-toggle name="encryption"
                            data-testid="connection-master-encryption-toggle"
                            angulartics2On="click"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: client-side encryption is switched"
                            [angularticsProperties]="{'enable': db.masterEncryption}"
                            [disabled]="accessLevel === 'readonly' || submitting || db.isTestConnection"
                            [(ngModel)]="db.masterEncryption"
                            [(ngModel)]="db.masterEncryption"
                            (ngModelChange)="generatePassword()">
                            Use client-side encryption
                        </mat-slide-toggle>
                        <div *ngIf="db.masterEncryption && masterKey" class="encription-password">
                            <mat-form-field appearance="outline" style="width:100%">
                                <mat-label>Master password</mat-label>
                                <input matInput data-testid="connection-master-password-input"
                                    name="masterPassword" #masterPassword="ngModel"
                                    readonly
                                    [(ngModel)]="masterKey">
                                <mat-hint>
                                    Rocketadmin does not store the key.
                                    Please save this password on your computer in protected place,
                                    we recommend using password managers.
                                    Share this password with all users of the connection.
                                </mat-hint>
                            </mat-form-field>
                            <button type="button" data-testid="connection-master-password-copy-button"
                                mat-icon-button
                                class="encription-password__copy-button"
                                matTooltip="Copy mater password"
                                [cdkCopyToClipboard]="masterKey"
                                (cdkCopyToClipboardCopied)="showCopyNotification('Master password was copied to clipboard.')">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                        </div>
                    </div>

                    <mat-form-field *ngIf="db.type === 'oracledb'" appearance="outline" class="advanced-settings__fullLine">
                        <mat-label>SID</mat-label>
                        <input matInput
                            data-testid="connection-sid-input"
                            angulartics2On="change"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SID is edited"
                            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                            [disabled]="submitting"
                            [(ngModel)]="db.sid" name="sid" #sid="ngModel">
                        <!-- <mat-error *ngIf="sid.invalid && sid.touched">Email should not be empty.</mat-error> -->
                    </mat-form-field>

                    <mat-checkbox class="checkbox-line advanced-settings__fullLine" name="ssh" #ssh="ngModel"
                        data-testid="connection-ssh-checkbox"
                        labelPosition="after"
                        angulartics2On="click"
                        angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSH is switched"
                        [angularticsProperties]="{'enable': db.ssh}"
                        [disabled]="submitting || db.isTestConnection"
                        [(ngModel)]="db.ssh">
                        Use SSH tunnel
                    </mat-checkbox>

                    <mat-form-field *ngIf="db.ssh" appearance="outline" class="advanced-settings__fullLine">
                        <mat-label>Private SSH key</mat-label>
                        <textarea matInput resizeToFitContent rows="8" name="privateSSHKey" #privateSSHKey="ngModel"
                            data-testid="connection-ssh-key-textarea"
                            angulartics2On="change"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSH key is edited"
                            [required]="db.ssh && !db.id" [readonly]="accessLevel === 'readonly' && db.id"
                            [disabled]="submitting"
                            [(ngModel)]="db.privateSSHKey"
                        ></textarea>
                        <mat-error *ngIf="privateSSHKey.errors?.required && (privateSSHKey.invalid && privateSSHKey.touched)">Private SSH key should not be empty.</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="db.ssh" appearance="outline">
                        <mat-label>SSH host</mat-label>
                        <input matInput name="sshHost" #sshHost="ngModel"
                            data-testid="connection-ssh-host-input"
                            angulartics2On="change"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSH host is edited"
                            [required]="db.ssh" [readonly]="accessLevel === 'readonly' && db.id"
                            [disabled]="submitting"
                            [(ngModel)]="db.sshHost">
                        <mat-error *ngIf="sshHost.errors?.required && (sshHost.invalid && sshHost.touched)">SSH host should not be empty.</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="db.ssh" appearance="outline">
                        <mat-label>SSH port</mat-label>
                        <input matInput type="number" name="sshPort" #sshPort="ngModel"
                            data-testid="connection-ssh-port-input"
                            angulartics2On="change"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSH port is edited"
                            [required]="db.ssh" [readonly]="accessLevel === 'readonly' && db.id"
                            [disabled]="submitting"
                            [(ngModel)]="db.sshPort">
                        <mat-error *ngIf="sshPort.errors?.required && (sshPort.invalid && sshPort.touched)">SSH port should not be empty.</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="db.ssh" appearance="outline">
                        <mat-label>SSH username</mat-label>
                        <input matInput name="sshUsername" #sshUsername="ngModel"
                            data-testid="connection-ssh-username-input"
                            angulartics2On="change"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSH username is edited"
                            [required]="db.ssh" [readonly]="accessLevel === 'readonly' && db.id"
                            [disabled]="submitting"
                            [(ngModel)]="db.sshUsername">
                        <mat-error *ngIf="sshUsername.errors?.required && (sshUsername.invalid && sshUsername.touched)">SSH username should not be empty.</mat-error>
                    </mat-form-field>

                    <mat-checkbox class="checkbox-line advanced-settings__fullLine" name="ssl" #ssh="ngModel"
                        labelPosition="after"
                        data-testid="connection-ssl-checkbox"
                        angulartics2On="click"
                        angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSL is switched"
                        [angularticsProperties]="{'enable': db.ssl}"
                        [disabled]="submitting || db.isTestConnection"
                        [(ngModel)]="db.ssl">
                        Check SSL certificate
                    </mat-checkbox>

                    <mat-form-field *ngIf="db.ssl" appearance="outline" class="advanced-settings__fullLine">
                        <mat-label>SSL certificate</mat-label>
                        <textarea matInput resizeToFitContent rows="8" name="sslCert" #sslCert="ngModel"
                            data-testid="connection-ssl-certificate-textarea"
                            angulartics2On="change"
                            angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: SSL certificate is edited"
                            [required]="db.ssl" [readonly]="accessLevel === 'readonly' && db.id"
                            [disabled]="submitting"
                            [(ngModel)]="db.cert"
                        ></textarea>
                        <mat-error *ngIf="sslCert.errors?.required && (sslCert.invalid && sslCert.touched)">SSL certificate should not be empty.</mat-error>
                    </mat-form-field>

                    <mat-checkbox *ngIf="db.type === 'mssql'" class="checkbox-line advanced-settings__fullLine" name="ssl" #ssh="ngModel"
                        labelPosition="after"
                        data-testid="connection-ssl-encryption-checkbox"
                        angulartics2On="click"
                        angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: azure encryption is switched"
                        [angularticsProperties]="{'enable': db.azure_encryption}"
                        [disabled]="submitting"
                        [(ngModel)]="db.azure_encryption">
                        Encryption
                    </mat-checkbox>
                </div>
            </mat-expansion-panel>

        </ng-container>

        <div *ngIf="db.connectionType === 'agent'" class="connectForm__fullLine instruction">

            <a mat-flat-button color="accent"
                [href]="osAgents[userOS]"
                data-testid="connection-agent-user-os-download-link"
                angulartics2On="mousedown"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: Download agent for user OS"
                [angularticsProperties]="{'downloadForUserOS': userOS}">
                Download for {{ userOS }}
            </a>
            <a mat-button  *ngFor="let os of otherOS"
                [href]="osAgents[os]" class="download-button"
                attr.data-testid="connection-agent-{{os}}-os-download-link"
                angulartics2On="mousedown"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: Download agent for OS"
                [angularticsProperties]="{downloadForOS: os}">
                Download for {{os}}
            </a>

            <div class="docker-instruction">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Installation
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ol class="instruction-steps">
                            <li class="instruction-step">
                                Download the <strong>binary file</strong> that fits your OS.
                            </li>
                            <li class="instruction-step">
                                Launch this file in <strong>the terminal</strong>.
                            </li>
                            <li class="instruction-step">
                                Using CLI, <strong>enter the token</strong> you received after you add agent connection.
                            </li>
                            <li class="instruction-step">
                                Continue and <strong>enter your database credentials</strong>, indicate 'localhost' as hostname.
                            </li>
                            <li class="instruction-step">
                                After entering credentials and settings, you will see the
                                "Credentials accepted. Try to <strong>launch application</strong>"
                            </li>
                            <li class="instruction-step">
                                Open the list of connections and check that your local <strong>database is connected to Rocketadmin interface</strong>.
                            </li>
                        </ol>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Installation via Docker
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ol class="instruction-steps">
                            <li class="instruction-step">
                                Install Docker. On Linux, please install
                                <a href="https://docs.docker.com/compose/install/" target="_blank"
                                    class="instruction-step__link">
                                    Docker Compose
                                </a>
                                as well.<br/>
                                Note: Docker Desktop on Windows and MacOS already include Docker Compose.
                            </li>
                            <li class="instruction-step">
                                Create <strong>docker-compose.yml</strong> file.
                                Copy and paste configuration from
                                <a href="https://github.com/rocket-admin/rocketadmin-agent/blob/master/docker-compose.yml" target="_blank"
                                    class="instruction-step__link">
                                    (source file)
                                </a>
                                or download this file.
                            </li>
                            <li class="instruction-step">
                                Create <strong>.config.env</strong> file in the same directory. Copy and paste the contents of
                                <a href="https://github.com/rocket-admin/rocketadmin-agent/blob/master/.config.env" target="_blank"
                                    class="instruction-step__link">
                                    (source file)
                                </a> or download this file.
                            </li>
                            <li class="instruction-step">
                                Open <strong>.config.env</strong> file and specify all required credentials.
                            </li>
                            <li class="instruction-step">
                                Open Terminal app and run:
                                <code class="instruction-command">docker-compose up --build</code>
                            </li>
                        </ol>
                        <p>After sucessfull execution, new connection will appear in Rocketadmin Connections List.</p>
                        <p>For more information visit <a href="https://github.com/rocket-admin/rocketadmin-agent" class="instruction-step__link">docs</a>.</p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>


            <div *ngIf="connectionToken" class="encription-password">
                <mat-form-field appearance="outline" style="width:100%">
                    <mat-label>Token</mat-label>
                    <input matInput name="token" #token="ngModel" readonly
                        data-testid="connection-agent-token-input"
                        [(ngModel)]="connectionToken">
                    <mat-hint>
                        Please save this token and use it to configure your agent connection in .config.env file.
                    </mat-hint>
                </mat-form-field>
                <button type="button" mat-icon-button
                    data-testid="connection-agent-token-copy-button"
                    class="encription-password__copy-button"
                    matTooltip="Copy mater password"
                    [cdkCopyToClipboard]="connectionToken"
                    (cdkCopyToClipboardCopied)="showCopyNotification('Connection token was copied to clipboard.')">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>
        </div>

        <!-- test connection actions -->
        <div *ngIf="db.isTestConnection" class="test-connection-actions">
            <p class="mat-body-1 test-connection-message">You cannot edit test connection.</p>
            <a *ngIf="db.isTestConnection"
                routerLink="/connect-db"
                data-testid="test-connection-actions-create-own-link"
                mat-flat-button color="accent">
                Create your own
            </a>
            <button type="button" mat-button color="warn"
                data-testid="test-connection-actions-delete-button"
                angulartics2On="click"
                angularticsAction="Connection creds test: delete db is clicked"
                [disabled]="submitting"
                (click)="confirmDeleteConnection(db)">
                Delete
            </button>
        </div>

        <!-- add connection actions -->
        <div class="actions" *ngIf="!db.id && !db.isTestConnection">
            <a mat-stroked-button routerLink="/connections-list"
                data-testid="add-connection-actions-back-link"
                class="delete-button">
                Back
            </a>
            <button *ngIf="db.connectionType === 'direct'" type="button" mat-button color="primary" class="test-button"
                data-testid="add-connection-actions-test-button"
                angulartics2On="click"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: test db creds is clicked"
                (click)="testConnection()"
                [disabled]="submitting">
                Test connection
            </button>
            <button mat-flat-button type="submit" *ngIf="!connectionToken && !connectionID"
                data-testid="add-connection-actions-add-button"
                color="primary"
                angulartics2On="click"
                angularticsAction="Connection creds add: db add clicked"
                [disabled]="submitting">
                {{ submitting ? 'Checking' : 'Add'}}
            </button>
            <a *ngIf="connectionToken && connectionID" mat-flat-button color="primary"
                routerLink="/dashboard/{{connectionID}}"
                data-testid="add-connection-actions-open-dashboard-link">
                Open dashboard
            </a>
        </div>

        <!-- edit connection actions -->
        <div class="actions" *ngIf="accessLevel && db.id && accessLevel === 'edit' && !db.isTestConnection">
            <button type="button" mat-button color="warn"
                class="delete-button"
                data-testid="edit-connection-actions-delete-button"
                angulartics2On="click"
                angularticsAction="Connection creds edit: delete db is clicked"
                [disabled]="submitting"
                (click)="confirmDeleteConnection(db)">
                Delete
            </button>
            <button type="button" mat-button color="primary" class="test-button"
                data-testid="edit-connection-actions-test-button"
                angulartics2On="click"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: test db creads is clicked"
                (click)="testConnection()"
                [disabled]="submitting">
                Test connection
            </button>

            <!-- right button -->
            <button type="submit" *ngIf="db.id"
                mat-flat-button color="primary"
                data-testid="edit-connection-actions-edit-button"
                angulartics2On="click"
                angularticsAction="Connection creds edit: db edit is clicked"
                [disabled]="submitting || connectForm.form.pristine || db.isTestConnection">
                {{ submitting ? 'Checking' : 'Edit'}}
            </button>

            <a *ngIf="connectionToken && connectionID" mat-flat-button color="primary"
                routerLink="/dashboard/{{connectionID}}"
                data-testid="edit-connection-actions-open-dashboard-link">
                Open dashboard
            </a>
        </div>
    </form>
</div>
