<mat-drawer-container class="drawer">
    <mat-drawer mode="side" opened>
        <div class="drawer-header">
            <h1 class="mat-h1">Rules</h1>

            <button mat-icon-button color="primary" *ngIf="rulesData && rules && rules.length !== 0"
                type="button" class="add-icon-button"
                angulartics2On="click"
                angularticsAction="Actions: add first action is clicked"
                (click)="addNewRule()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="no-actions" *ngIf="rulesData && rules && rules.length === 0 && !newRule">
            <span class="mat-body-1">No rules added</span>
            <button mat-stroked-button color="primary" type="button"
                angulartics2On="click"
                angularticsAction="Actions: add action is clicked"
                (click)="addNewRule()">
                <mat-icon>add</mat-icon>
                Add rule
            </button>
        </div>
        <mat-action-list *ngIf="rulesData && rules && rules.length">
            <button mat-list-item *ngFor="let rule of rules; let i = index"
                class="list-action-list-item"
                [ngClass]="{
                    'list-action-list-item_active': selectedRule && rule.title === selectedRule.title,
                    'list-action-list-item_notSaved': rule.id === ''
                }"
                (click)="switchRulesView(rule)">
                <span>{{ rule.title }}</span>
            </button>
        </mat-action-list>
        <mat-form-field *ngIf="newRule" appearance="fill" class="new-action-input">
            <input matInput autofocus="true" name="newRuleTitle" #newActionTitle="matInput"
                required
                placeholder="Enter rule name"
                [(ngModel)]="newRule.title"
                (keyup.enter)="handleAddNewRule()">
            <span *ngIf="actionNameError" class="action-error">{{actionNameError}}</span>
            <button mat-icon-button matSuffix
                (click)="undoRule()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </mat-drawer>

    <mat-drawer-content *ngIf="rulesData">
        <app-alert></app-alert>
        <app-breadcrumbs [crumbs]="getCrumbs(currentConnection.title || currentConnection.database)" class="actions-breadcrumbs"></app-breadcrumbs>
        <app-content-loader *ngIf="!rules"></app-content-loader>

        <div class="rule" *ngIf="selectedRule && rules.length">
            <form (ngSubmit)="handleRuleSubmitting()" class="rule-settings">
                <mat-form-field appearance="outline" class="rule-name">
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="selectedRuleTitle" name="rule-title" required>
                </mat-form-field>
                <div class="event">
                    <span class="mat-body-1 text_highlighted">When</span>
                    <br/>
                    <div *ngFor="let event of selectedRule.events; let i = index">
                        <div class="event">
                            <span *ngIf="i > 0" class="mat-body-1 event__or text_highlighted">or</span>
                            <mat-form-field appearance="outline">
                                <mat-label>Events</mat-label>
                                <mat-select name="{{event.event}}-event-type"
                                    [required]="selectedRule.events.length === 1"
                                    [(ngModel)]="event.event"
                                    (selectionChange)="onEventChange($event)">
                                    <mat-option *ngFor="let availableEvent of availableEvents" [value]="availableEvent.value"
                                        [disabled]="selectedEvents.includes(availableEvent.value)">
                                        {{ availableEvent.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button *ngIf="selectedRule.events.length > 1 && event.event !== null"
                                mat-icon-button matSuffix
                                type="button"
                                class="event__removeButton"
                                matTooltip="Remove event"
                                (click)="removeEvent(event.event)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedRuleCustomEvent" class="custom-event">
                    <span class="mat-body-1">Custom event</span>

                    <div class="custom-event__row">
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput name="action-title" [(ngModel)]="selectedRuleCustomEvent.title" required>
                        </mat-form-field>
                        <app-icon-picker class="icon-picker"
                            [icon]="selectedRuleCustomEvent.icon"
                            [defaultIcons]="defaultIcons"
                            tooltip="Choose an icon"
                            (onFieldChange)="updateIcon($event)">
                        </app-icon-picker>
                        <mat-checkbox name="action-confirmation" #confirmation="ngModel"
                            labelPosition="after"
                            class="confirmation-checkbox"
                            [(ngModel)]="selectedRuleCustomEvent.require_confirmation">
                            Request confirmation
                        </mat-checkbox>
                    </div>

                    <div class="custom-event__row">
                        <mat-radio-group
                            name="action-type"
                            [(ngModel)]="selectedRuleCustomEvent.type">
                            <mat-label>Affects</mat-label>
                            <mat-radio-button value='single' class="radio-button_first" checked>
                                Single row
                            </mat-radio-button>
                            <mat-radio-button value='multiple' class="radio-button_second">
                                Multiple rows
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <span class="mat-body-1"><span class="text_highlighted">then</span> perform action(s)</span>
                <div *ngFor="let action of selectedRule.table_actions" class="rule-action">
                    <mat-form-field appearance="outline">
                        <mat-label>Actions type</mat-label>
                        <mat-select name="action-column" [(ngModel)]="action.method">
                            <mat-option value="EMAIL">Email notification</mat-option>
                            <mat-option value="SLACK">Slack notification</mat-option>
                            <mat-option value="URL">URL webhook</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="action.method === 'URL'" class="rule-action__param">
                        <mat-label>Action URL</mat-label>
                        <input matInput [(ngModel)]="action.url" name="action-url" required>
                        <!-- <mat-error *ngIf="sslCert.errors?.required && (sslCert.invalid && sslCert.touched)">SSL certificate should not be empty.</mat-error> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="action.method === 'SLACK'" class="rule-action__param">
                        <mat-label>Slack URL</mat-label>
                        <input matInput [(ngModel)]="action.slack_url" name="action-slack-url" required>
                        <!-- <mat-error *ngIf="sslCert.errors?.required && (sslCert.invalid && sslCert.touched)">SSL certificate should not be empty.</mat-error> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="action.method === 'EMAIL'" class="rule-action__param">
                        <mat-label>Emails</mat-label>
                        <mat-select multiple [(ngModel)]="action.emails" name="notification-emails">
                            <mat-option *ngFor="let companyMember of companyMembers" [value]="companyMember.email"><span *ngIf="companyMember.name">{{companyMember.name}} | </span> {{companyMember.email}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="action__actions">
                    <button mat-stroked-button color="warn" type="button"
                        (click)="handleRemoveRule()">
                        Delete
                    </button>

                    <button mat-flat-button color="primary" type="submit"
                        [disabled]="submitting || !selectedRuleTitle">
                        Save
                    </button>
                </div>
            </form>

            <!--<div *ngIf="selectedRuleCustomEvent" class="code-snippet-box">
                <mat-form-field appearance="outline" class="lang-select">
                    <mat-label>Language</mat-label>
                    <mat-select [(ngModel)]="codeLangSelected">
                        <mat-option *ngFor="let lang of codeSnippets | keyvalue; let key = index; trackBy:trackByFn" [value]="lang.key">{{lang.value.langName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <button type="button"
                    mat-icon-button
                    class="copy-button"
                    matTooltip="Copy mater password"
                    [cdkCopyToClipboard]="codeSnippets[codeLangSelected].snippet[selectedRuleCustomEvent.type]"
                    (cdkCopyToClipboardCopied)="showCopyNotification(codeSnippets[codeLangSelected].langName + 'code snippet was copied to clipboard.')">
                    <mat-icon>content_copy</mat-icon>
                </button>

                <div class="action-codeSnippet">
                    <ngs-code-editor
                        theme="vs"
                        [codeModel]="{
                            language: codeSnippets[codeLangSelected].mode,
                            value: codeSnippets[codeLangSelected].snippet[selectedRuleCustomEvent.type]
                        }"
                        [options]="codeViewerOptions">
                    </ngs-code-editor>
                </div>
            </div> -->
        </div>

    </mat-drawer-content>
  </mat-drawer-container>
