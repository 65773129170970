<div class="background-decoration">
    <app-alert></app-alert>
    <div class="wrapper">
        <form action="" class="password-form"
            #requestPasswordForm="ngForm"
            (ngSubmit)="companyId ? requestPassword() : requestUserCompanies()">
            <h1 class="mat-h1">Forgot your password?</h1>
            <p class="mat-body-1">
                Enter your Email below and we will send a message to reset your password.
            </p>
            <mat-form-field appearance="outline" class="email-field">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="email" #email="ngModel" required autofocus emailValidator
                    data-testid="forget-password-email-input"
                    [(ngModel)]="userEmail" >
                <mat-error *ngIf="email.errors?.isInvalidEmail" data-testid="forget-password-email-error">Invalid email format.</mat-error>
            </mat-form-field>

            <div *ngIf="isLoadingUserCompanies" class="password-form__field-loader"></div>
            <mat-form-field appearance="fill" *ngIf="userCompanies && userCompanies.length > 1">
                <mat-label>Company</mat-label>
                <mat-select [(value)]="companyId" required>
                  <mat-option *ngFor="let company of userCompanies; let i = index" [value]="company.id">
                    {{company.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <button *ngIf="!companyId && !(userCompanies && userCompanies.length > 1)"
                type="submit" mat-stroked-button
                data-testid="forget-password-next-button"
                [disabled]="isLoadingUserCompanies || userEmail === ''">
                {{ submitting ? 'Submitting' : 'Next'}}
            </button>
            <button *ngIf="userCompanies && userCompanies.length"
                type="submit" mat-flat-button color="primary" class="password-form__reset-button"
                data-testid="forget-password-submit-button"
                [disabled]="submitting || requestPasswordForm.form.invalid || requestPasswordForm.form.pristine">
                {{ submitting ? 'Submitting' : 'Reset my password' }}
            </button>
        </form>
    </div>
</div>
